"use strict";

const { UtilityExtension } = require("./common_utility_extension");

let singletonInstance;
/**
 * @typedef IParseKeyOptions
 * @property [splitSeparator] {string} The separator between the typeCode and the ID (defaults to "-")
 * @property [skipIDParsing] {boolean} If true, does not parse the ID as an integer.
 */

/**
 * @type {{skipIDParsing: boolean, splitSeparator: string}}
 */
const DEFAULT_PARSE_KEY_OPTIONS = {
  splitSeparator: "-",
  skipIDParsing: false,
};

/**
 * This class is responsible for parsing a model key into an object with information about that key.
 *
 * For instance, for PRJ-23, it will tell you it's a project, its id is 23, its type code is PRJ, and it's not a custom ID.
 */
class ModelKeyParser extends UtilityExtension {
  /**
   * Retrieves the current singleton instance.
   * @param commonUtils {CommonUtils}
   * @returns {ModelKeyParser}
   */
  static instance(commonUtils) {
    if (!singletonInstance) {
      singletonInstance = new ModelKeyParser(commonUtils);
    }
    return singletonInstance;
  }

  /**
   * Given a key (ex. PRJ-23) this converts it to a map (ex. {typeCode: "PRJ", id: 23, modelName: "Project", isCustomId: false} )
   * @param key {string} The key to be parsed (e.g: PRJ-23)
   * @param options {IParseKeyOptions} The options to parse the key.
   * @returns {IRecordKey|null} Returns an instance of {@link IRecordKey} or null if no key is specified.
   */
  parseKey(key, options = {}) {
    options = Object.assign({}, DEFAULT_PARSE_KEY_OPTIONS, options || {});

    let { splitSeparator, skipIDParsing, extractName } = options;

    let result = null;
    if (key) {
      let [typeCode, id, name] = key.split(splitSeparator);
      // Attempts to retrieve the model name to check whether this is a regular ID or a custom ID.
      let modelName = this.commonUtils.findModelNameForTypeCode(typeCode);

      // If we didn't retrieve a valid model name, we assume it's a custom ID.
      let isCustomId = modelName === null;

      // we have a valid ID, so we try to parse it or return it as a string
      if (id) {
        // custom IDs may have padded numbers, so we leave them unparsed
        if (isCustomId || skipIDParsing) {
          id = String(id);
        } else {
          id = this.commonUtils.parseInt(id);
        }
      } else {
        // if there's no numeric ID, we assume it's a custom ID format
        isCustomId = true;
      }

      result = {
        typeCode,
        id,
        name,
        modelName,
        isCustomId,
      };

      if (extractName) {
        result.name = name.split("\n")[0].trim();
      }
    }
    return result;
  }

  /**
   * Converts the given key into one that works better for sorting. For example, "FQA-1" will become "FQA-000001".
   *
   * @param fullKey {string} The key to convert.
   * @return {string} The key that can be used for sorting.
   */
  getKeyForSorting(fullKey) {
    const key = this.parseKey(fullKey);
    return key.typeCode + "-" + ("00000" + key.id).substr(-6);
  }
}

module.exports = {
  ModelKeyParser,
};
